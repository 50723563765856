#slick-product{
    .slick-list{
        min-height: 400px;
    }
}

#slick-home-universe-wp {
  position: relative;
  height: 820px;
  overflow: hidden;

  #slick-home-universe-arrows {
    .slick-arrow {
      position: absolute;
      bottom: 20px;
      z-index: 2;

      &.arrow-prev {
        left: 20px;
      }

      &.arrow-next {
        right: 20px;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
	#slick-home-universe {
		.item {
			height: 822px;
      background-size: cover;
      background-position: center;
		}
	}

  @include media-breakpoint-down(md) {
    height: 400px;
    #slick-home-universe {
      .item {
        height: 400px;
      }
    }

  }
  @include media-breakpoint-down(sm) {
    height: 300px;
    #slick-home-universe {
      .item {
        height: 300px;
      }
    }

  }
}

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: 15px;
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 7px;
		height: 7px;
		margin-right: 5px;
		background-color: map.get($colors, 'black');
		border-radius: 100%;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'pink');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'pink');
			}
		}
	}
}

@keyframes expand-border {
 0% {
  width: 0;
  margin-left: 0;
  opacity: 1;
 }
 100% {
  width: 60px;
  margin-left: -30px;
  opacity: 1;
 }
}


#slick-header-wp {
  position: relative;

  @media (max-width: 1130px) {
    &:not(.home){
      margin-bottom: 90px;
    }
  }

  &.inner-page {
    height: 450px;

    .title-1 {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        width: 60px;
        opacity: 0;
        height: 2px;
        left: 50%;
        margin-left: -30px;
        background-color: map.get($colors, "pink");
        animation: expand-border .4s ease-in .3s forwards;
      }
    }

    &:after {
      background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    }

    .slick-heading {
      padding: 90px 0 0;
    }

    .item {
      height: 450px;
    }
    @media (max-width: 1130px) {
      height: 300px;

      .slick-heading {
        padding: 100px 0 0;
      }
      &:after {
        top: 60px;
      }
      .item {
        height: 300px;
      }
    }

    &.small {
      height: 175px;
      .item {
        height: 175px;
      }
      @media (max-width: 1130px) {
        height: 0;
        overflow: hidden;
        margin-bottom: 80px;
        padding: 0;
        //display: none;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    z-index: 1;
  }

  .slick-heading {
    position: absolute;
    top: 0;
    left: 0;
	  padding-top: 150px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1130px) {
    padding-top: 60px;
  }

	.item {
    background-size: cover;
    background-position: center;
  }

	#slick-arrows {

		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}

	.slick-slide {
		.content {
			opacity: 0;
			transform: translateY(-30px) scale(1);
			@include transition(all, 0.2s, ease-in-out, 0.5s);
		}
		&.animation {
			.content {
				opacity: 1;
				transform: translateY(0) scale(1);
			}
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}

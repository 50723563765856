.search-open {
  .search-form {
    width: 400px;
    opacity: 1;
    right: 3px;
    
    @media (max-width: 1130px) {
      width: calc(100vw - 240px);
    }

    @include media-breakpoint-down(sm) {
      width: calc(100vw - 170px);
    }
  }
  #navigation-main {
    opacity: .4;
  }
}

#navigation-main {
  transition: opacity .2s ease-in-out;
}

.search-form {
  position: absolute;
  top: -5px;
  right: 5px;
  width: 0px;
  opacity: 0;
  border-radius: 30px;
  overflow: hidden;
  transition: all .2s ease-in-out;

  button {
    &.submit {
      display: block;
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      width: 40px;
    }

    &.close {
      position: absolute;
      top: 12px;
      right: 10px;

      .material-icons {
        @include font-size(18);
      }
    }
  }

  .form-control {
    padding-top: 12px;
    padding-bottom: 9px;
    padding-right: 50px;
    border: none !important;
    @include font-size(14);
  }

  @media (max-width: 1130px) {
    button{
      &.close {
        top: 10px;
      }
    }
    .form-control {
      padding-top: 9px;
      padding-bottom: 7px;
    }
  }

}


@media (min-width: 1130px) {
  .header-small {
    .header-bottom {
      position: fixed;
      top: 0;
      margin-top: -60px;
      height: 60px;
      left: 0;
      opacity: 0;
      background-color: rgba(0,0,0,.8);


  
      &.animate {
        transition: opacity .2s ease-in-out, margin .3s ease-in-out;
      }
      
      .logo {
        top: 8px;
        width: 110px;
      }

      #navigation-main {
        top: 10px;
        @media (max-width: 1650px) {
          margin-left: -420px;    
        }
      
        @media (max-width: 1350px) {
          margin-left: -350px;    
        }
      } 
      .extra-icons {
        top: 15px;
      }
    }

    &.visible {
      .header-bottom {
        margin-top: 0;
        opacity: 1;
      }
    }
  }
}


.menu-open {
  #navigation-main {
    display: flex;
  }
  @media (max-width: 1130px) {
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left:0;
      position: fixed;
      background-color: rgba(0,0,0,.9);
      z-index: 2;
    }
  }

}
.header-top {
  display: block;
  background-color: map.get($colors, 'pink');
  color: $white;
  padding: 9px 0;
  font-family: $font-regular;
  @include font-size(14);

  .list-inline-item {
    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  @media (max-width: 1130px) {
    display: none;
  }
}

#header {
	// background-color: map.get($colors, 'black');
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 100000;
	@include transition(all, 0.2s, ease-in-out);
}

.header-bottom {
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  z-index: 2;

  .extra-icons {
    position: absolute;
    top: 30px;
    right: 50px;
    display: flex;
    
    .shopping-cart-number {
      padding: 3px 4px 2px 3px;
      line-height: 1em;
      display: inline-block;
      color: map.get($colors, 'white');
      border-radius: 4px;
      position: absolute;
      top: -2px;
      right: -2px;
      font-family: $font-semi-bold;
      background-color: map.get($colors, 'pink');
      @include font-size(8);
    }

    li {
      position: relative;
    }
  }

  .logo {
    position: absolute;
    width: 240px;
    left: 40px;
    top: 0;

    > *:last-child {
      display: none;
    }
  }

  @media (max-width: 1350px) {
    .logo {
      width: 200px;
      top: 5px;
    }
  }

  @media (max-width: 1130px) {
    position: fixed;
    top: 0;
    height: 60px;
    background-color: map.get($colors, "black");
    z-index: 2;
    opacity: 1 !important;

    .logo {
      position: relative;
      top: 8px;
      left: auto;
      display: block;
      margin: 0 auto;
      width: 40px;

      > *:first-child {
        display: none;
      }
      
      > *:last-child {
      display: block;
      }
    }

    .extra-icons {
      top: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    .extra-icons {
      right: 15px;
    }
  }


}

#navigation-main {
	display: flex;
  position: absolute;
  width: 1000px;
  margin-left: -525px;
  top: 25px;
  left: 50%;
  

	.item {
		flex-grow: 1;
	}

	.link {
		color: map.get($colors, 'white');
		display: block;
    position: relative;
		text-align: center;
		//font-family: $font-semi-bold;
		letter-spacing: 0.05em;
		padding: 15px 10px;
		@include font-size(14);

    &:after {
      content: "";
      width: 0;
      height: 1px;
      background-color: $white;
      position: absolute;
      bottom: 8px;
      left: 50%;
      z-index: 1;
      transition: all .2s ease-in-out;
      opacity: 0;
    }

    &.active {
      &:after {
        width: 15px;
        margin-left: -7.5px;
        opacity: 1;
      }
    }

    &:hover {
      &:after {
        width: 15px;
        margin-left: -7.5px;
        opacity: 1;
      }
    }

    @media (max-width: 1130px) {
      @include font-size(22);
    }

		
		&:focus,
		&:hover {}

		&.active {}
	}

	.navigation-sub {
		li {
      margin: 10px 0;
    }

		.link-sub {
      @include font-size(14);
      transition: all .2s ease-in-out;

			&:focus,
			&:hover,
      &.active {
        color: map.get($colors, 'pink');
      }	
		}
	}

  @media (max-width: 1650px) {
    width: 850px;
    margin-left: -375px;    
  }

  @media (max-width: 1350px) {
    width: 700px;
    margin-left: -300px;    
  }
  
  @media (max-width: 1130px) {
    display: none;
    position: fixed;
    top: 60px;
    margin-top: 0;    
    justify-content: space-between;
    flex-flow: column nowrap;
    left:0;
    padding: 40px 0;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 60px);
    margin-left: 0;

    .item {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: 1130px) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

#button--mobile {
  position: fixed;
  top: 20px;
  left: 50px;
  z-index: 3;

  @include media-breakpoint-down(sm) {
    left: 15px;
  }
}


li {
	&[data-navsub] {
		position: relative;
	}
}

@media (min-width: 1130px) {
	li {
		&[data-navsub] {
			&.focus {
				> .navigation-sub {
					height: auto;
					opacity: 1;
          padding: 12px 20px;
					top: 50px;
					visibility: visible;
				}
			}
		}
	}

  .header-small {
    .navigation-sub {
      border-radius: 0 0 4px 4px;
      color: map.get($colors, 'white');
      background-color: rgba(0,0,0,.8);
    }
  } 

	.navigation-sub {
		background-color: rgba(255, 255, 255, .9);
		height: 0;
    border-radius: 4px;
		left: 50%;
    margin-left: -95px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		width: 190px;
		z-index: 99;
    transition: opacity .2s ease-in-out;
	}
}


@media (max-width: 1130px) {
  .navigation-sub {
    position: relative !important;
    color:  map.get($colors, 'white');
    opacity: 1 !important;
    text-align: center;
    display: none;
    background-color: transparent !important;
    &.open {
      display: block;
    }

    li {
      margin: 15px 0 !important;
    }
    .link-sub {
      font-size: 16px !important;
    }
  }
}


body {
	&.header-small {
		#header {
			@include media-breakpoint-up(lg) {}
		}
	}
}

#anchor-content {
  @media (max-width: 1130px) {
    position: relative;
    width: 100%;
    left: 0;
    top: -60px;
  }
}

#header-page {

  .link {
    text-decoration: underline;
    color: map.get($colors,"white");
  }

  .icon-list {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      margin: 0 4px;
    }

    .material-icons {
      display: block;
      position: relative;
      top: 1px;
      color: $white;
      transition: all .05s ease-in-out;
      &:hover {
        transform: translateY(2px);
      }
    }
  }


}
$sprites : (
  (scroll-top, 40px, 40px, 0 0),
  (click-collect, 70px, 70px, -40px 0),
  (handcrafted, 70px, 70px, -110px 0),
  (payment, 70px, 70px, -180px 0),
  (hero-scroll, 30px, 70px, -250px 0),
  (arrow-right-pink, 30px, 30px, -280px 0),
  (arrow-left-black, 30px, 30px, -310px 0),
  (arrow-right-black, 30px, 30px, -340px 0),
  (instagram, 30px, 30px, -370px 0),
  (facebook, 20px, 30px, -412px 0),
  (whatsapp, 30px, 30px, -430px 0),
  (points-of-sale, 30px, 30px, -460px -2px),
  (search, 30px, 30px, -280px -30px),
  (account, 30px, 30px, -310px -30px),
  (shopping-cart, 30px, 30px, -340px -30px),
  (phone, 20px, 20px, -370px -30px),
  (by-pascal-icon, 30px, 30px, -390px -30px),
  (search-black, 20px, 20px, -420px -30px),
  (logout, 30px, 30px, -440px -30px),

);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }

  &.instagram,
  &.facebook,
  &.whatsapp,
  &.search,
  &.account,
  &.shopping-cart {
    transition: all .05s ease-in-out;
    &:hover {
      transform: translateY(2px);
    }
  }
}
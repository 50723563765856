.cta-category {
  .link {
    background-size: cover;
    background-position: center center;
    display: block;
    border-radius: 6px;
    color: $white;
    height: 450px;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: all .2s ease-in-out;

    .header {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 15px 15px;
      z-index: 2;
      transition: all .2s ease-in-out .1s;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      opacity: .9;
      z-index: 1;
      transition: all .2s ease-in-out;
    }

    @include media-breakpoint-down(lg) {
      .title-3 {
        @include font-size(22);
      }
      .title-6 {
        @include font-size(16);
        line-height: 1.3em;
      }
    }



    @include media-breakpoint-down(md) {
      height: 200px;
    }

    &:hover {
      transform: translateY(-6px);

      .header {
        bottom: 15px;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.home-about {
  > .row {
    min-height: 800px;
  }
  .image-background {
    background-repeat: no-repeat;
    height: 100%;
    background-position: center top;
    width: 100%;
    background-size: cover;

  } 
}

.home-universe-wp {
  color: $white;
  .col-universe {
    width: 600px;
    @include media-breakpoint-down(xxl) {
      width: 540px;
    }
    @include media-breakpoint-down(xl) {
      width: 440px;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-left: 30px;
    }
  }

  .home-universe-navigation {
    li {
      margin-top: 35px;
      display: block;
    } 
    button {
      display: block;
      text-align: left;
      width: 100%;
      position: relative;
      padding-left: 30px;
      @include font-size(19);

      &:before {
        content: "";
        @extend .sprite;
        @extend .arrow-right-pink;
        position: absolute;
        top: 50%;
        left: -15px;
        margin-top: -18px;
        transition: all .2s ease-in-out;
        opacity: 0;
      }

      &:after {
        content: "";
        width: 0%;
        height: 2px;
        background-color: map.get($colors, 'pink');
        position: absolute;
        z-index: 1;
        bottom: -10px;
        opacity: 0;
        left: 30px;
        transition: all .2s ease-in-out;
      }

      &:hover,
      &.active {
        font-family: $font-semi-bold;
        &:before {
          left: -5px;
          opacity: 1;
        }
        &:after {
          opacity: 1;
          width: calc(100% + 35px);
        }
      }
    }
    @include media-breakpoint-down(md) {
      li {
        margin-top: 15px;
      }
      button {
        @include font-size(17);
        &:after {
          display: none;
        }
      }
    }
  }
}

.masonry-like {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  &.two-col {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .item {
    display: block;
    background-size: cover;
    background-position: center;
    border-radius: 5px;

    &.one {
      grid-area: 1 / 2 / 13 / 1;
    }
    &.two {
      grid-area: 13 / 2 / 22 / 1;
    }
    &.three {
      grid-area: 8 / 2 / 1 / 2;
    }
    &.four {
      grid-area: 22 / 2 / 8 / 2;
    }
    &.five {
      grid-area: 20 / 3 / 1 / 3;
    }    
    &.six {
      grid-area: 20 / 3 / 22 / 3;
    }    
  }

  .link {
    line-height: 1.2em;
    position: relative;
    display: block;
    transition: all .2s ease-in-out;
    
    .material-icons {
      display: block;
      transition: all .1s ease-in-out;
    }

    &:hover {
      .material-icons {
        transform: translateX(5px);
      }
    }
  }

  @include media-breakpoint-down(md) {
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .link {
      .title-3 {
        @include font-size(18);
      }
    }
  }  
}
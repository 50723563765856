.form-check {
  display: flex;
  align-items: center;

  &-label {
    margin-left: 10px;
  }

  &-input:checked {
    + label {
      color: map.get($colors, 'pink');
    }
  }


}

.form-control {
	color: $black!important;
	padding: 17px 15px 12px;
	@include font-size(15);
  &:disabled {
    background-color: #eee !important;
    + .ui-datepicker-trigger {
      display: none;
    }
  }
  &:not(:disabled){

    &:hover,
    &:focus {
      border-color: #ced4da !important;
      box-shadow: 0 0 10px rgba(0,0,0,.1) !important;
    }
  }

  &::placeholder {
    color: lighten($black, 50%);
  }
}

.form-check-input {
  &.larger {
    width: 35px;
    height: 35px;
  }
}

.select2-container {
	text-align: left;

	&.select2-container--default {
		.select2-selection--single {
			height: 54px;
      border-radius: .375rem;
			border-color: $gray-400;

			.select2-selection {
				&__placeholder {
					opacity: 1;
					color: lighten($black, 50%);
				}

				&__rendered {
					line-height: 3.4em;
					color: map.get($colors, 'dark');
          padding-left: 15px;
				}

				&__arrow {
					top: 12px;
					right: 10px;
          opacity: .6;
				}
			}

		}
	}

  .select2-dropdown {
    border-color: #dee2e6;
  }

  .select2-results__option {
    padding: 15px;
  }
	.select2-results__option--highlighted {
		&.select2-results__option--highlighted {
			background-color: map.get($colors, 'black');
		}
	}
}

.file-picker {
  display: inline-block;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
	position: relative;
	width: 100%;

	&--input {
    height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
		position: relative;
		width: 100%;
		z-index: 2;
	}

	&--label {
    height: calc(2.25rem + 2px);
    left: 0;
		position: absolute;
    top: 0;
    right: 0;
		z-index: 1;
		@extend .form-control;

		&::after {
			align-items: center;
			background-color: $black;
			bottom: 0;
			color: $white;
			display: flex;
			justify-content: center;
			height: calc(calc(2.25rem + 2px) - 1px * 2);
			padding: 0 15px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
		}
	}

	&--input:lang(fr) ~ .file-picker--label {
    &::after {
      content: "Rechercher";
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	/*border: none !important;*/
	-webkit-text-fill-color: inherit !important;
	/*-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;*/
	transition: background-color 5000s ease-in-out 0s;
}

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-state-default.ui-state-active.ui-state-hover {
	border: none;
	background: map.get($colors, 'black') !important;
}

#ui-datepicker-div {
  width: 415px !important;
	top: auto !important;
  left: auto !important;
  z-index: 10 !important;
  padding: 5px 10px 0 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  border: 0 !important;
  box-shadow: 1px 5px 7px rgba(0, 0, 0, .2);
  @include font-size(16);
  font-family: $font-regular;
  margin-top: -55px;
  @include media-breakpoint-down(xxl) {
    width: 360px !important;
  }
  @include media-breakpoint-down(xl) {
    width: 300px !important;
  }
  @include media-breakpoint-down(lg) {
    width: 100% !important;

  }

  th {
    font-weight: 400 !important;
  }
  .ui-datepicker-title {
    font-weight: 400;
    font-family: $font-regular;
    text-transform: uppercase;
  }

  .ui-state-default {
    border-radius: 10px;
    padding: 10px 10px 8px;

      &.ui-state-hover,
      &.ui-state-active {
        color: map.get($colors, 'white') !important;
        background-color:map.get($colors, 'pink') !important;
      }
  }

  .ui-widget-header {
		background: map.get($colors, 'white') !important;
    a:hover {
      transform: scale(.8) translateY(2px);
    }
    > * {
      border: 0 !important;

    }
  }
}

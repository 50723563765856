$colors: (
  'black': #1E1E1E,
  'white': #FFFFFF,
  'grey': #f2f2f2,
  'grey-darker': #9b9b9b,
  'error': #a94442,
  'blue': #352b92,
  'pink': #E63589,
  'green': #ccffcc,
  'orange': #F88379,
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}
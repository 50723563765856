$buttons: (
  (button-regular,
  inline-block,
  14,
  $font-regular,
  uppercase,
  1em,
  12px 15px 9px,
  center,
  0,
  map.get($colors, 'black'),
  $white,
  1px solid map.get($colors, 'black'),
  $white,
  map.get($colors, 'black'),
  1px solid map.get($colors, 'black')),
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    position: relative;
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    overflow: hidden;
    text-transform: $text-transform;
    @include font-size($font-size);

    span {
      position: relative;
      z-index: 1;
      transition: all .2s ease-in-out .1s;
    }

    &:after {
      content: "";
      background-color: map.get($colors, 'black');
      position: absolute;
      bottom: -100%;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      transition: all .1s ease-in-out;
    }

    &.border-white {
      border-color: $white;
      border: 0 !important;
    }

    &.radius {
      border-radius: 6px;
    }

    &.bolder {
      font-weight: 400;
      font-family: $font-semi-bold;
    }

    &.pink {
      background-color: map.get($colors, 'pink');
      color:  map.get($colors, 'white');
      border-color: map.get($colors, 'pink');
      &:after {
        background-color: map.get($colors, 'white');
      }
      &:hover, &:focus {
        color: map.get($colors, 'pink');
      }
    }


    &.grey {
      background-color: lighten(map.get($colors, 'black'), 80%);
      border-color: lighten(map.get($colors, 'black'), 80%);
      &:after {
        background-color: map.get($colors, 'white');
      }
      &:hover {
        color: map.get($colors, 'black');
      }
    }

    &.larger {
      padding: 19px 23px 16px;
      @include font-size(16);
    }

    &:hover,
    &:focus {
      color: $hover-color;


      &:after {
        bottom: 0;
      }
    }
  }
}

.hero-scroll-button {
  position: relative;
  display: inline-block;
  transition: all .2s ease-in-out;

  .hero-scroll-animate {
    display: block;
    position: absolute;
    top: 10px;
    left: 12px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: $white;
    animation: 2s ease-in 1s infinite move-circle;
  }

  &:hover {
    transform: translateY(5px);
  }
}

@keyframes move-circle {
  0% {
    top: 10px;
  }
  40% {
    top: 40px;
  }
  40% {
    top: 40px;
    opacity: 1;
  }
  60% {
    top: 40px;
    opacity: 0;
  }
  100% {
    top: 40px;
    opacity: 0;
  }
}

.button-alt {
  position: relative;
  display: inline-block;
  line-height: 1.2em;
  color: $white !important;
  padding-bottom: 10px;
  @include font-size(19);

  &:after {
    content: "";
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 5px;
    left: 0;
    background-color: map.get($colors, 'pink');
    transition: all .2s ease-in-out;
  }

  &.with-shadow {
    text-shadow: 0 2px 2px rgba(0,0,0,.2)
  }

  &:hover {
    &:after {
      width: 60%;
      left: 20%;
    }
  }
}


.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 30px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);

    span {
      background: $white;
      display: block;
      height: 1px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }

    span:nth-child(4) {
      top: 18px;
    }
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;

    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }

    &:hover,
    &:focus {}
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}

.pagination {
  justify-content: center;
  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        background-color:#f7f7f7;
      }
    }
  }
  .page-link {
    padding-bottom: 11px;
    font-family: $font-semi-bold;
    line-height: 1.2em;
    box-shadow: none !important;

    &:hover,
    &.active {
      color: map.get($colors, 'pink');
      border-color: #dee2e6;
      background-color: map.get($colors, 'grey');
    }
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  margin: 0 auto;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}

.breadcrumb {
  @include font-size(14);
}

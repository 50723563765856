@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'poppinssemibold';
  src: url('fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsbold';
  src: url('fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsregular';
  src: url('fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-regular: 'poppinsregular', sans-serif;
$font-semi-bold:  'poppinssemibold', sans-serif;
$font-bold: 'poppinsbold', sans-serif;

$fonts: (
  (title-1, 64, $font-regular, normal, 1.1em, title),
  (title-2, 38, $font-semi-bold, normal, 1.2em, title),
  (title-3, 27, $font-semi-bold, normal, 1.2em, title),
  (title-4, 24, $font-semi-bold, normal, 1.2em, title),
  (title-5, 24, $font-regular, normal, 1.2em, title),
  (title-6, 18, $font-regular, normal, 1.4em, title),
  (title-7, 22, $font-semi-bold, normal, 1.1em, title),
  (text-1, 14, $font-semi-bold, normal, 1.3em, text),
  (text-2, 14, $font-regular, normal, 1.2em, text),
  (text-3, 16, $font-regular, normal, 1.8em, text),
  (text-4, 12, $font-regular, italic, 1.4em, text),
  (text-5, 16, $font-semi-bold, normal, 1.8em, text),
);

strong {
 font-family: $font-semi-bold;
}

mark {
  display: -webkit-inline-box;
  padding: 10px 15px 5px;
  line-height: 1em;
  color: $white;
  background-color: map.get($colors, 'yellow');
  &.small {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-regular;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    display: block;
    line-height: $line-height;
    font-style: $font-style;
    font-family: $font-weight;
    @include font-size($font-size);

    &.with-shadow {
      text-shadow: 0 2px 2px rgba(0,0,0,.2)
    }

    &.with-border {
      padding-bottom: 5px;
      border-bottom: 3px solid map.get($colors, 'pink');
    }

    &.with-small-border {
      &:after {
        content: "";
        display: block;
        width: 25px;
        height: 2px;
        margin-top: 4px;
        background-color: map.get($colors, 'black');
      }
    }

    @if $type ==title {
      @include media-breakpoint-down(lg) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.2)));
      }
    }

    @if $label == title-1 {
      @include media-breakpoint-down(sm) {
        @include font-size(calc(math.div($font-size, 1.4)));
      }
    }

    // @include media-breakpoint-down(sm) {
    //   @if type == text and size > 15 {
    //     @include font-size(calc(math.div($font-size,1.3)));
    //   } 
    // }
  }
}
$breakpoints: xs, sm, md, lg, xl, xxl;
$sizes: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
$types: ma, pa, mv, mh, mt, mr, mb, ml, pv, ph, pt, pr, pb, pl;

@each $breakpoint in $breakpoints {
  @if($breakpoint ==xs) {
    @each $size in $sizes {
      @each $type in $types {
        .#{$type}-#{$size} {
          @include spacing-generator($size, $type);
        }
      }
    }
  }

  @else {
    @include media-breakpoint-up($breakpoint) {
      @each $size in $sizes {
        @each $type in $types {
          .#{$type}-#{$breakpoint}-#{$size} {
            @include spacing-generator($size, $type);
          }
        }
      }
    }
  }
}
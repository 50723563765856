#footer {
	background-color: map.get($colors, 'black');
	color: $white;
	padding: 70px 0 30px;

  .border-top {
    
  }

  .list-inline-item {
    margin-right: 0 !important;
  }

  .link-phone {
    display: inline-block;
    padding-left: 27px;
    position: relative;
    
    &:before {
      content:"";
      display: block;
      position: absolute;
      left: 0;
      top: -4px;
      @extend .sprite;
      @extend .phone;
    }
  }

	a {
		display: inline-block;
    transition: all .2s ease-in-out;
    color: $white !important;

    &:hover {
      color: $white;
      opacity: .6;
      transform: scale(.98);
    }
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {
    padding: 50px 0 20px;
  }

	@include media-breakpoint-down(sm) {
    padding: 30px 0;
  }
}
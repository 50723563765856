.product-item {

  figure {
    position: relative;

    .by-pascal {
      position: absolute;
      right: 0;
      top: 0px;
      background-color: $white;
      padding: 0;
      border-radius: 3px;
      width: 60px;
      line-height: 1em;
      color: map.get($colors, 'pink');
      text-align: center;
      @include font-size(10);
      > * {
        display: inline-block;
      }
    }
  }

  .link {
    display: block;
    color: map.get($colors, 'black') !important;

    .img-fluid {
      transition: all .2s ease-in-out;
    }

    .header {
      transition: all .3s ease-in-out ;
    }

    &:hover {
      .img-fluid {
        transform: translateY(3px);
        opacity: .8;
      }
      .header {
        transform: translateY(-3px);
      }
    }
  }

  &.listing {
    height: 100%;
    text-align: center;

    .link {
      height: 100%;
      padding-bottom: 30px;
      position: relative;

      .header {
        bottom: 0px;
        position: absolute;
        left: 0;
        height: 100px;
        width: 100%;
        background-color: rgba(255, 255, 255, .7);
        backdrop-filter: blur(2px);
        padding: 20px 10px;

        > * {
          line-height: 1em;
        }

        img {
          transition: all .2s ease-in-out;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          background: linear-gradient(to right,  rgba(230,53,137,0) 0%,rgba(230,53,137,1) 17%,rgba(230,53,137,1) 50%,rgba(230,53,137,1) 51%,rgba(230,53,137,1) 79%,rgba(230,53,137,0) 100%);
          top: 0;
          left: 0;
          border-radius: 100px;
          transition: all .2s ease-in-out;
        }
      }


      &:hover {

        img {
          opacity: .5;
          transform: scale(.98);
        }

        .header {
          background-color: rgba(255, 255, 255, 1);
          &:before {
            opacity: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0 40px;
      }
    }

    @include media-breakpoint-down(sm) {
      &.mobile-small {
        .link {
          padding: 0;

          .header {
            height: 120px;
            bottom: -60px;
            padding: 15px 10px 0;

            > .title-7:first-child {
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              @include font-size(16);
            }
            .text-3 {
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              @include font-size(12);
            }

          }
        }
      }
    }

  }
}

.label-availability {
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: $font-bold;
  line-height: 1em;
  text-transform: uppercase;
  padding: 7px 10px 5px;
  @include font-size(11);
  > * {
    vertical-align: middle;
  }

  .material-icons {
    @include font-size(16);
    margin-right: 5px;
  }

  &.available {
    background-color: map.get($colors, 'green');
  }
  &.not-available {
    background-color: map.get($colors, 'orange');
  }
}

.shopping-cart-order {

  input[type='radio'] {
    width: 17px;
    height: 17px;
  }
  label {
    margin-top: 4px;
  }

  .popover-icon {
    .material-icons {
      @include font-size(20);
      margin-left: 10px;
      color:lighten($black,40%);

    }
  }
}


.shopping-cart-product {
  background-color: map.get($colors, 'grey');
  border-radius: 6px;
  padding: 30px;

  &.except {
    padding: 20px;
  }

  @include media-breakpoint-down(md) {
    padding: 15px;
  }

  @include media-breakpoint-down(sm) {
    &:not(.except) {
      padding: 20px;
    }
  }

  .button-regular {
    border-radius: 6px;
    overflow: hidden;
  }

  input[type='checkbox'] {
    width: 35px;
    height: 35px;
  }

  .list-inline {
    > *:first-child {
      position: relative;
      &:after {
        content: "|";
        position: absolute;
        display: block;
        @include font-size(12);
        top: 2px;
        color: lighten($black,60%);
        right: -9px;
      }
    }
  }


}

.btn-action {
  @include font-size(14);
  color: lighten($black,60%);
  text-decoration: underline;
  transition: all .2s ease-in-out;

  &:hover {
    color: map.get($colors, 'black');
  }
}

@include media-breakpoint-down(lg) {
  .zoom-wrapper {
    max-width: 400px;
  }
}

@include media-breakpoint-down(sm) {
  .zoom-wrapper {
    max-width: 300px;
  }
}

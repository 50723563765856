.connexion-border {
  position: relative;
  &:after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: map.get($colors, 'grey');    
  }
  @include media-breakpoint-down(lg) {
    border-bottom: 1px solid  map.get($colors, 'grey');
    padding-bottom: 30px;
    &:after {
      display: none;
    }
  }
}

.tooltip {
  @include font-size(12);
}

.table {
  border-radius: 10px;
  overflow: hidden;
  .thead-dark {
    background-color: map.get($colors, 'black');   
    color: map.get($colors, 'white');   
    tr {
      border-top: 0;

    } 
    * {
      padding: 20px !important;
      text-transform: uppercase;
      @include font-size(14);
    }
  }
  * {
    vertical-align: middle;
    padding: 20px !important;
    @include font-size(15);
    .btn-download {
      padding: 0 !important;
    } 
    .material-icons {
      padding: 0 !important;
      @include font-size(20); 
      text-align: left;
      color: map.get($colors, 'pink');   

    }
  }
}
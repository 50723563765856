body,
html {
  width: 100%;
  background-color: map.get($colors, 'white');
  line-height: 1em;
}

body {
  overflow: hidden;
  overflow-y: auto;
}

html {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-weight: 400;
  margin-bottom: 0;
}

figure {
  margin: 0;
}

button {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none !important;
  padding: 0;

  // &:focus,
  // &:active,
  // &:visited {
  //   color: inherit;
  // }
}

a {
  color: inherit;
  text-decoration: none;

  // &:hover,
  // &:focus {
  //   color: inherit;  
  //   cursor: pointer;
  //   text-decoration: none;
  // }
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;

  ol,
  ul {
    list-style: none;
    padding: 0;
  }
}

p {
  margin-bottom: 0;
}

@viewport {
  width: device-width;
  zoom: 1;
}

.renderer-optmimize {
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

@include media-breakpoint-down(md) {
  .equal-col-item {
    min-height: 0 !important;
  }
}